import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DestroyRef, inject } from '@angular/core';
import { GenericException } from '../../domain/entities/exceptions';
import { UserEntity, UserException, UserProfile } from '../../domain/entities/user.entity';
import { UsersRepositoryPort } from '../../domain/ports/users.repository.port';

import { TRPCClientError } from '@trpc/client';
import { TRPCClient as client } from '../clients/trpc-client';

export class TRPCUsersRepositoryAdapter implements UsersRepositoryPort {
  private readonly http = inject(HttpClient);
  private readonly destroyRef = inject(DestroyRef);

  async getCurrentUser(): Promise<UserEntity> {
    const dto = await client.user.getCurrent.query();

    if (!dto) {
      throw new UserException('user-not-found');
    }

    return new UserEntity(
      dto.id,
      dto.email,
      dto.displayName,
      dto.lang,
      dto.isEmailVerified,
      dto.areCurrentTermsAccepted,
      dto.permissions,
      dto.firstname,
      dto.lastname,
    );
  }

  async signOut(): Promise<void> {
    await client.auth.signOut.mutate();
  }

  async signIn(email: string, password: string): Promise<void> {
    try {
      await client.auth.signIn.mutate({ username: email, password });
    } catch (e) {
      this.handleError(e);
    }
  }

  async signUp(email: string, password: string): Promise<void> {
    try {
      await client.auth.signUp.mutate({ username: email, password });
    } catch (e) {
      this.handleError(e);
    }
  }

  async saveCurrentUserProfile(profile: UserProfile): Promise<UserEntity> {
    const dto = await client.user.saveProfile.mutate(profile);

    return new UserEntity(
      dto.id,
      dto.email,
      dto.displayName,
      dto.lang,
      dto.isEmailVerified,
      dto.areCurrentTermsAccepted,
      dto.permissions,
      dto.firstname,
      dto.lastname,
    );
  }

  async setNewPassword(password: string): Promise<void> {
    try {
      await client.user.savePassword.mutate({ password });
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        if (e.status === 422) {
          throw new UserException('password-too-short');
        }
      }
    }
  }

  private handleError(e: unknown) {
    if (e instanceof TRPCClientError) {
      switch (e.data.code) {
        case 'NOT_FOUND':
          throw new UserException('user-not-found');
        case 'UNAUTHORIZED':
          throw new UserException('bad-credentials');
        case 'CONFLICT':
          throw new UserException('email-already-used');
        case 'UNPROCESSABLE_CONTENT':
          throw new UserException('password-too-short');
        default:
          throw new GenericException('unknown-error');
      }
    }
  }
}
