import { Injectable } from '@angular/core';
import { TRPCClientError } from '@trpc/client';
import { GenericException } from '../../domain/entities/exceptions';
import {
  SecurityChallengeChannel,
  SecurityChallengeEntity,
  SecurityChallengeException,
  SecurityChallengeScope,
} from '../../domain/entities/security-challenge.entity';
import { SecurityChallengesRepositoryPort } from '../../domain/ports/security-challenges.repository.port';
import { TRPCClient as client } from '../clients/trpc-client';

@Injectable()
export class TRPCSecurityChallengesRepositoryAdapter implements SecurityChallengesRepositoryPort {
  async takeChallenge(
    scope: SecurityChallengeScope,
    channel: SecurityChallengeChannel,
    channelIdentifier: string,
  ): Promise<SecurityChallengeEntity> {
    const dto = await client.user.takeSecurityChallengeWhileUnauthenticated.mutate({
      scope,
      channel,
      channelIdentifier,
    });

    if (!dto) {
      throw new SecurityChallengeException('not-found');
    }

    return new SecurityChallengeEntity(
      dto.challengeId,
      dto.type,
      dto.scope,
      dto.channel,
      dto.channelIdentifier,
      new Date(dto.expiresAt),
      new Date(dto.canResendAt),
    );
  }

  async submitChallenge(
    challengeId: SecurityChallengeEntity['id'],
    type: 'one-time-password',
    challengeSubmittal: string,
  ): Promise<{ grant: 'reset_password' | null }> {
    try {
      const trpcResponse = await client.user.submitSecurityChallenge.mutate({
        challengeId,
        type,
        challengeSubmittal,
      });

      return trpcResponse;
    } catch (e) {
      if (e instanceof TRPCClientError) {
        switch (e.data.code) {
          case 'NOT_FOUND':
            throw new SecurityChallengeException('not-found');
          case 'UNAUTHORIZED':
            throw new SecurityChallengeException('invalid-code');
        }
      }

      throw new GenericException('unknown-error', e);
    }
  }
}
