import { isPlatformBrowser } from '@angular/common';
import { InjectionToken, PLATFORM_ID, Provider } from '@angular/core';
import { CurrentUserStateAdapter } from 'src/infrastructure/adapters/current-user.state.adapter';
import { CurrentUserStatePort } from '../domain/ports/current-user.state.port';
import { SecurityChallengesRepositoryPort } from '../domain/ports/security-challenges.repository.port';
import { UsersRepositoryPort } from '../domain/ports/users.repository.port';
import { TRPCSecurityChallengesRepositoryAdapter } from '../infrastructure/adapters/trpc.security-challenges.repository.adapter';
import { TRPCUsersRepositoryAdapter } from '../infrastructure/adapters/trpc.users.repository.adapter';

export const APP_DOMAIN = new InjectionToken<string>('APP_DOMAIN');

export default () => {
  return [
    {
      provide: APP_DOMAIN,
      useFactory: (platform: string) => {
        let url = '';
        if (isPlatformBrowser(platform)) {
          const { protocol, host } = window.location;
          url = `${protocol}//${host}`;
        }

        return url;
      },
      deps: [PLATFORM_ID],
    },
    {
      provide: UsersRepositoryPort,
      // useClass: HttpUsersRepositoryAdapter,
      useClass: TRPCUsersRepositoryAdapter,
    },
    {
      provide: SecurityChallengesRepositoryPort,
      useClass: TRPCSecurityChallengesRepositoryAdapter,
    },
    {
      provide: CurrentUserStatePort,
      useClass: CurrentUserStateAdapter,
    },
  ] as Provider[];
};
