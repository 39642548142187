import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { NotifierServicePort } from '../../common/ports/notifier.service.port';
import { BrowserNotifierServiceAdapter } from '../../infrastructure/adapters/browser.notifier.service.adapter';

export function provideSharedDomain(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: NotifierServicePort,
      useClass: BrowserNotifierServiceAdapter,
    },
  ]);
}
