import { bootstrapApplication } from '@angular/platform-browser';
import { init as initSentry } from '@sentry/angular';
import { appConfig } from './app.config';
import { AppComponent } from './components/app.component';

initSentry({
  dsn: 'https://cacb60a695b0c10fa43c1f1a2d62b160@o4507642448314368.ingest.us.sentry.io/4507754109206528',
});

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
