import { Routes } from '@angular/router';
import { ifNotSignedIn } from '../guards/signed-in.guard';
import { UserPrerequisiteData, userPrerequisitesGuard } from '../guards/user-prerequisites.guard';
import {
  securityChallengeResolver,
  SecurityChallengeResolverData,
} from '../pages/channel-verification/security-challenge.resolver';

export default [
  {
    path: 'auth/sign-up',
    canActivate: [ifNotSignedIn],
    loadComponent: () => import('../pages/sign-up/sign-up-page.component').then((m) => m.SignUpPageComponent),
  },
  {
    path: 'auth/sign-in',
    canActivate: [ifNotSignedIn],
    loadComponent: () => import('../pages/sign-in/sign-in-page.component').then((m) => m.SignInPageComponent),
  },
  {
    path: 'auth/reset-password',
    canActivate: [ifNotSignedIn],
    data: {
      challengeScope: 'reset_password',
      challenge: securityChallengeResolver,
    } as SecurityChallengeResolverData,
    loadComponent: () =>
      import('../pages/reset-password/reset-password-page.component').then((m) => m.ResetPasswordPageComponent),
  },
  {
    path: 'auth/set-password',
    loadComponent: () =>
      import('../pages/set-password/set-password-page.component').then((m) => m.SetPasswordPageComponent),
  },
  {
    path: 'auth/take-challenge',
    resolve: {
      challenge: securityChallengeResolver,
    },
    data: {
      forPrerequisite: 'verify_email',
    } as UserPrerequisiteData & SecurityChallengeResolverData,
    runGuardsAndResolvers: 'always',
    loadComponent: () =>
      import('../pages/channel-verification/channel-verification-page.component').then(
        (m) => m.ChannelVerificationPageComponent,
      ),
  },
  {
    path: 'auth/create-profile',
    canActivate: [userPrerequisitesGuard],
    loadComponent: () =>
      import('../pages/update-account/update-account-page.component').then((m) => m.UpdateAccountPageComponent),
    data: {
      forPrerequisite: 'complete_profile',
    } as UserPrerequisiteData,
  },
  {
    path: 'account',
    canActivate: [userPrerequisitesGuard],
    loadComponent: () =>
      import('src/framework/pages/account/account-page.component').then((m) => m.AccountPageComponent),
  },
] as Routes;
