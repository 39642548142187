import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ProgressBarComponent } from '../ui/progress-bar/progress-bar.component';

@Component({
  selector: 'ls-root',
  imports: [RouterOutlet, ProgressBarComponent],
  template: `
    <ls-progress-bar></ls-progress-bar>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {}
