import { Injectable, signal } from '@angular/core';
import { UserEntity } from '../../domain/entities/user.entity';
import { CurrentUserStatePort } from '../../domain/ports/current-user.state.port';

@Injectable()
export class CurrentUserStateAdapter implements CurrentUserStatePort {
  private initialized = signal(false);
  private currentUser = signal<UserEntity | null>(null);

  isInitialized(): boolean {
    return this.initialized();
  }

  getCurrentUser(): UserEntity | null {
    return this.currentUser();
  }

  setCurrentUser(user: UserEntity | null): void {
    this.currentUser.set(user);
    this.initialized.set(true);
  }
}
