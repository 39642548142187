import { Routes } from '@angular/router';

import { ifSignedIn } from './guards/signed-in.guard';
import currentUserRoutes from './routing/current-user.routes';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: () => {
      return 'auth/sign-in';
    },
  },
  {
    path: 'drills',
    loadChildren: () => import('./routing/drills.routes'),
    canActivateChild: [ifSignedIn],
    title: 'Drills',
  },
  ...currentUserRoutes,
  {
    path: '**',
    redirectTo: '',
  },
];
