import { EnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { CurrentUserController } from '../controllers/current-user.controller';

export const loadCurrentUserProvider: EnvironmentProviders = provideAppInitializer(async () => {
  const usersController = inject(CurrentUserController);
  try {
    await usersController.fetchCurrentUser();
  } catch (e) {
    console.error(e);
  }
});
