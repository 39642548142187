import {
  EnvironmentProviders,
  ErrorHandler,
  inject,
  makeEnvironmentProviders,
  provideAppInitializer,
} from '@angular/core';
import { Router } from '@angular/router';
import { createErrorHandler, TraceService } from '@sentry/angular';

export const sentryProviders: EnvironmentProviders = makeEnvironmentProviders([
  {
    provide: ErrorHandler,
    useValue: createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: TraceService,
    deps: [Router],
  },
  provideAppInitializer(() => {
    inject(TraceService);
  }),
]);
